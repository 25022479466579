<template>
<div style="margin-top: 38px;">
    <div class="about">
        <div class="about__center center">
            <h2 class="about__subtitle h2 aos-init aos-animate" data-aos="animation-scale-top" data-aos-duration="600">Termini e condizioni</h2>

        </div>
        <vs-row style="width: 100%; gap: 30px;" justify="center">
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">PREMESSA</h2>
                <p style="text-align: left;">Questo contratto e le seguenti Condizioni Generali, in conformità alle disposizioni in materia di vendita dei beni di consumo a norma dei contenuti del decreto legislativo n. 206 6 settembre 2005 e successive integrazioni e modificazioni, tra le quali il decreto legislativo 21 febbraio 2014, n. 21, hanno per oggetto la vendita a distanza tramite il sito internet denominato www.remindmevision.com.
                    Tutti i prezzi esposti su RemindMe Vision sono comprensivi di IVA, ma non comprendono le spese di trasporto che sono determinate in relazione al peso complessivo, alla distanza e alla difficoltà della consegna. Tali spese verranno comunque segnalate prima della conclusione dell'acquisto, dopo aver inserito l'indirizzo di consegna e vanno corrisposte secondo la normativa all'art. 7 delle presenti Condizioni.
                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 1 - Titolarità del sito</h2>
                <p style="text-align: left;">
                    Il sito internet www.remindmevision.com è di titolarità della startup innovativa RemindMe S.r.l., con sede legale in Corso giacomo Matteotti 42 10121 Torino (TO) e sede operativa in Strada del Mondino 13/D Castiglione Torinese (TO) 10090, P.I. 12783780013– R.E.A. Numero TO – 1316058, ed è finalizzato alla vendita di prodotti tipografici personalizzati o al deposito di materiale e dati personali sotto forma di “ricordi". Nel presente documento RemindMe S.r.l. sarà indicata con il nome “RemindMe Vision” che è il segno distintivo sotto il quale esercita l'attività di vendita nel Sito.
                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 2 - Oggetto e legge applicabile</h2>
                <p style="text-align: left;">
                    Le presenti Condizioni Generali hanno per oggetto l'acquisto di prodotti a distanza, tramite rete telematica sul sito "www.remindmevision.com".
                    Ogni operazione d'acquisto sarà regolata dalla legge italiana, si rinvia in particolare al decreto legislativo 2005, n. 206, recante il "Codice del Consumo", e successive integrazioni e modificazioni , tra le quali il decreto legislativo 21 febbraio 2014, n. 21 "Attuazione della direttiva 2011/83/UE sui diritti dei consumatori" e nonché, per quanto concerne la tutela della riservatezza, sarà sottoposta alla normativa di cui al D.lgs. 30/06/2003 n. 196.
                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 3 - Accettazione delle Condizioni Generali di Vendita</h2>
                <p style="text-align: left;">
                    Accedendo al sito remindmevision.com il Cliente si impegna ad accettare e concordare di attenersi al rispetto di tutte le disposizioni contenute nelle presenti Condizioni Generali di Vendita.
                    Il Cliente, con l'invio telematico della conferma del proprio ordine d'acquisto, accetta incondizionatamente e si obbliga ad osservare nei suoi rapporti con Remindmevision.com le seguenti Condizioni Generali e le modalità di pagamento di seguito trascritte, dichiarando di aver preso visione e accettato tutte le indicazioni a lui fornite, ai sensi delle norme sopra richiamate, prendendo altresì atto che Remindmevision.com non si ritiene vincolato a condizioni diverse se non preventivamente concordate per iscritto.
                    Remindmevision.com si riserva il diritto di chiedere ai Clienti che non accettano o non intendono rispettare tali termini di astenersi dall'utilizzo del sito web
                    www.remindmevision.com.

                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 4 - Obblighi del Cliente</h2>
                <p style="text-align: left;">
                    Le presenti Condizioni Generali di Vendita devono essere esaminate online dai Clienti visitatori del sito remindmevision.com prima che essi confermino i loro acquisti. L'inoltro della conferma d'ordine, pertanto, implica la totale conoscenza delle Condizioni Generali di Vendita e la loro integrale accettazione.
                    Il Cliente si impegna e obbliga, una volta conclusa la procedura d'acquisto online, a stampare e conservare (o memorizzare su supporto durevole) le presenti Condizioni Generali di Vendita, già visionate e accettate durante il processo d'acquisto effettuato, al fine di soddisfare integralmente la condizione di cui al Capo I del titolo III della parte III del Decreto legislativo 6 settembre 2005 n. 206 e successive modifiche.
                    L'acquisto dei prodotti è riservato a persone maggiori di età e che abbiano la capacità di agire. Remindmevision.com declina ogni responsabilità nel caso in cui soggetti incapaci agiscano in violazione di tale regola.
                    L'acquisto di prodotti su remindmevision.com presuppone il conferimento, da parte del Cliente, dei dati necessari per l'effettuazione delle attività di consegna dei beni.
                    A tal fine, il Cliente si impegna a comunicare dati corretti, aggiornati e veritieri relativi ad indicazioni anagrafiche, recapito personale ed, in generale, alle altre informazioni utili all'evasione dell'ordine. Il Cliente si obbliga al pagamento del prezzo del bene acquistato.

                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 5 - Conclusione del Contratto</h2>
                <p style="text-align: left;">
                    Prima della conferma dell'acquisto al Cliente verrà riepilogato il costo unitario di ogni prodotto (incluso Iva), il costo totale in caso di acquisto di più prodotti e le relative spese di trasporto.
                    È dovere del Cliente verificare la correttezza dei dati inseriti al momento dell'ordine. Confermando l'acquisto, a completamento della transazione, il Cliente riceverà un messaggio di posta elettronica contenente il numero, la data, e l'importo complessivo del proprio ordine.
                    Il contratto si intenderà concluso una volta che Remindmevision.com avrà registrato il modulo d'ordine previa verifica della correttezza dei dati relativi all'ordine e al pagamento, e inviato al Cliente il messaggio di cui sopra, fatto salvo il diritto di recesso di cui ai successivi artt. 25 e 26 del presente documento.
                    Remindmevision.com invita il Cliente a conservare l'e-mail ricevuta come prova di acquisto o di memorizzarla su supporto durevole. Il modulo d'ordine sarà archiviato nella banca dati di Remindmevision.com per il tempo necessario e, comunque, nei termini di legge. Per accedere al proprio modulo d'ordine il Cliente potrà consultare la sezione "i miei dati" del sito dove potrà visionare l'elenco di tutti gli ordini effettuati.
                    Qualora il Cliente non condivida, alcuni dei termini riportati nelle Condizioni Generali di Vendita, Remindmevision.com invita a non inoltrare l'ordine e a contattare il servizio Assistenza Clienti per qualsiasi chiarimento.
                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 6 - Prezzi di Vendita</h2>
                <p style="text-align: left;">
                    Tutti i prezzi indicati sul sito Remindmevision.com sono espressi in (€) Euro e si intendono comprensivi di Iva.
                    La corretta ricezione dell'ordine è confermata da Remindmevision.com mediante risposta via e-mail, inviata all'indirizzo di posta elettronica comunicata al Cliente. Tale messaggio di conferma riporterà tutti i dati inseriti dal Cliente che si impegna a verificarne la correttezza e a comunicare tempestivamente eventuali correzioni.
                    Saranno a carico del Cliente le spese di spedizione, chiaramente visualizzate prima del perfezionamento dell'ordine.
                    In caso di discordanza dei prezzi, successivi all'ordine del Cliente, questi verranno immediatamente comunicati al Cliente telefonicamente o a mezzo e-mail che, senza nessuna penale, potrà recedere dall'impegno d'acquisto assunto.
                    Le immagini e i dati tecnici a corredo della scheda di un prodotto possono non essere complete ed esaustive delle caratteristiche proprie ma differenti per esempio per: contenuto, colore, etc.
                    In caso di errori nell'inserimento dei prezzi e/o malfunzionamento del sistema, Remindmevision.com si riserva la facoltà di annullare l'ordine a suo insindacabile giudizio. Eventuali pagamenti a saldo verranno restituiti al Cliente.

                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 7 - Pagamenti</h2>
                <p style="text-align: left;">
                    Il Cliente che intenda procedere all'acquisto dei prodotti deve manifestare tale volontà attraverso una richiesta effettuata direttamente sul sito, nella sezione appositamente dedicata denominata “Checkout”, ove, seguendo le procedure ivi indicate, invierà il proprio ordine d'acquisto ed effettuerà il pagamento.
                    Il pagamento del corrispettivo è dovuto dal momento dell'ordine. Il Cliente si impegna a pagare il prezzo concordato per il prodotto ordinato sul sito (prezzo dei prodotti e del trasporto) mediante i metodi di pagamento disponibili. Il Cliente garantisce a Remindmevision.com di disporre delle eventuali autorizzazioni necessarie per utilizzare lo strumento di pagamento indicato in fase di ordine.
                    I prodotti resteranno di proprietà di Remindmevision.com fino all'avvenuto pagamento del prezzo di acquisto e delle spese da parte del Cliente.
                    In caso di mancata ricezione del pagamento da parte di Remindmevision.com, si riserva la facoltà di annullare l'ordine.
                    Il Cliente può effettuare il pagamento a mezzo Carta di Credito o Carta di Debito.
                    Nel caso di pagamento mediante Carta di Credito, Remindmevision.com si riserva il diritto di richiedere l'invio, entro 24 ore, della copia del documento d'identità comprovante l'effettiva titolarità della carta di credito utilizzata, restando inteso che, in mancanza dell'invio richiesto, Remindmevision.com potrà rifiutare il pagamento e cancellare l'ordine. I dati relativi al pagamento saranno trattati attraverso una connessione server-to-server sicura mediante utilizzo del Protocollo SSL (Secure Sockets Layer). Questo sistema si occupa di criptare le informazioni digitate, in modo da rendere impossibile la loro intercettazione da parte di utenti esterni. Tali informazioni, inoltre, non saranno mai utilizzate da Remindmevision.com se non per completare le procedure di acquisto e per emettere i relativi rimborsi in caso di eventuali resi dei prodotti, a seguito di esercizio del diritto di recesso del Cliente, ovvero qualora si renda necessario prevenire o segnalare alle forze di polizia la commissione di frodi su Remindmevision.com. Le informazioni personali fornite possono essere comunicate ad un istituto di credito o ad un'agenzia antifrode che può tenere registro di tali informazioni.
                    Al momento della trasmissione dell'ordine non sarà effettuato alcun addebito sulla Carta di credito utilizzata dal Cliente per il pagamento. L'addebito sarà effettuato solo dopo: 1) aver verificato i dati della Carta utilizzata dal Cliente per il pagamento; 2) la società emittente della Carta di credito utilizzata dall'utente avrà rilasciato autorizzazione all'addebito.
                    Remindmevision.com invierà la merce solo dopo aver verificato la ricezione del pagamento stesso.

                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 8 - Spedizioni</h2>
                <p style="text-align: left;">
                    Le spese di spedizione sono a carico del Cliente e verranno comunque segnalate prima della conclusione dell'acquisto, dopo aver inserito l'indirizzo di consegna.
                    L'ordine verrà eseguito nei termini precisati sul sito ed all'accettazione dell'ordine da parte di Remindmevision.com e comunque nei 30 giorni successivi alla conferma dell'ordine con riserva della disponibilità del prodotto ordinato e salvo accordo specifico tra le parti.
                    I tempi di consegna, possono variare, a seconda della disponibilità dei prodotti e della scelta della modalità di pagamento da parte del Cliente, così come statuito dall'art. 7 delle presenti Condizioni. I tempi di consegna indicati sono meramente indicativi e non sono in nessun modo impegnativi.
                    Remindmevision.com invita il Cliente eventualmente a contattare il servizio Assistenza Clienti prima dell'acquisto, qualora i tempi di consegna, siano determinanti ai fini della decisione dell'acquisto.
                    Le consegne a ridosso dei periodi festivi potrebbero subire ritardi legati alle contingenze ed ai giorni di chiusura del periodo, un ritardo di produzione o consegna antecedente la chiusura festiva delle aziende produttrici e società di trasporti, potrebbe causare il posticipo della consegna di qualche settimana.
                    Le consegne vengono effettuate da partner logistici specializzati, al piano stradale (nessuna consegna al piano). Le spese relative all'eventuale ricorso ad elevatori sono a carico del Cliente.
                    Il Cliente dovrà indicare nell'ordine se ci sono situazioni di disagio per arrivare nel luogo di consegna come zona ZTL, vie strette, isole pedonali etc.
                    Tutti i costi per eventuali servizi supplementari sono a carico del Cliente.
                    Remindmevision.com non è responsabile per eventuali ritardi imputabili alla società di spedizione.
                    Per ogni ordine il Cliente verrà informato circa lo stato di avanzamento dello stesso: in fase di gestione e di spedizione. Remindmevision.com fornirà al Cliente il Tracking Number e tutti i dettagli per poter monitorare lo stato della spedizione.
                    Al ricevimento dell'ordine, il Cliente dovrà controllare che il numero dei colli indicato sulla lettera di vettura sia effettivamente quello consegnato.
                    Inoltre, il Cliente dovrà controllare la conformità dei prodotti consegnati a quelli ordinati e in particolare l'integrità e l'assenza di difetti esteriori.
                    Solo effettuata tale verifica, il Cliente dovrà sottoscrivere la bolla di consegna, dopodiché non potrà opporre alcuna contestazione circa le caratteristiche esteriori di quanto a lui consegnato in relazione all'ordine effettuato. Eventuali contestazioni vanno segnalate al corriere, indicando sulla lettera di vettura che il Cliente dovrà firmare per il ritiro, la dicitura "accettato con riserva di controllo" specificando le motivazioni, in caso contrario il pacco si considera consegnato correttamente.

                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 9 - Giacenza della spedizione</h2>
                <p style="text-align: left;">
                    In caso di mancata consegna dell'ordine di acquisto, in assenza del destinatario all'indirizzo specificato, il corriere incaricato lascerà una cartolina per certificare il tentativo di consegna.
                    Dopo due tentativi di consegna con esito negativo il pacco andrà in giacenza.
                    In caso di mancata consegna della spedizione, il giorno successivo la società di vettori incaricata invia automaticamente a Remindmevision.com il dossier di apertura pratica di giacenza, che riporta il motivo del mancato recapito.
                    Anche il Cliente ha la possibilità di visualizzare il dossier di apertura pratica di giacenza e lo stato del proprio ordine direttamente sul pannello di controllo del proprio profilo personale. Le spese di giacenza sono a carico del Cliente.
                    Remindmevision.com provvederà a contattare il Cliente al fine di sbloccare la giacenza e fare in modo che la consegna avvenga prima possibile.
                    In assenza di disposizioni del Cliente e trascorsi 3 (tre) giorni lavorativi dall'apertura della pratica di giacenza, il prodotto acquistato verrà rispedito a Remindmevision.com.
                    Decorsi dieci giorni lavorativi dalla data in cui il prodotto è stato rispedito a Remindmevision.com, e in assenza di disposizione da parte del Cliente, il contratto si intenderà risolto e l'ordine di acquisto annullato ai sensi dell'art. 1456 c.c.
                    Remindmevision.com non procederà al rimborso della somma pagata dal Cliente.
                    Nel caso in cui, prima del decorso dei dieci giorni, il Cliente chieda di ricevere nuovamente il prodotto acquistato, Remindmevision.com procederà alla nuova consegna previo addebito, oltre alle spese di restituzione del prodotto a Remindmevision.com. Spetta al destinatario verificare le condizioni del prodotto consegnato.
                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 10 - Fatturazione</h2>
                <p style="text-align: left;">
                    La fattura sarà inviata tramite mail. Per l'emissione della fattura fanno fede le informazioni fornite dal Cliente durante la procedura di acquisto. Il Cliente dovrà prestare attenzione a compilare correttamente tutti i suoi dati di fatturazione (compreso il Codice Fiscale o la Partita IVA, se in suo possesso).
                    Gli importi saranno riportati in fattura solo in Euro.
                    I dati per la fatturazione, inseriti in fase di ordine, possono essere modificati solo fino all'emissione della fattura stessa. Il Cliente potrà modificare i dati di fatturazione ad ogni nuovo ordine, trasmesso sul sito.
                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 11 - Indisponibilità prodotti ordinati</h2>
                <p style="text-align: left;">
                    Una volta ricevuta la richiesta d'ordine, il reparto ordini di Remindmevision.com si riserva il diritto di riconfermare la disponibilità degli articoli acquistati e verificare i dettagli delle precedenti transazioni effettuate dal Cliente su Remindmevision.com La disponibilità visualizzata all'interno della scheda-prodotto è puramente indicativa, vista la possibilità di acquisti plurimi nello stesso istante da parte di più utenti.
                    Nell'eventualità in cui gli articoli ordinati non siano disponibili o, se per qualche motivo l'ordine non possa essere evaso come da richiesta del Cliente, l'ufficio ordini Remindmevision.com darà tempestiva comunicazione al Cliente, il quale potrà decidere se modificare l'ordine con altro articolo o richiedere il rimborso per il singolo prodotto non disponibile.
                    In qualsiasi caso, nessuna responsabilità potrà essere imputata a Remindmevision.com.
                    Tutti i prodotti inseriti all'interno del proprio carrello non si intendono come prenotati e quindi, fino a quando l'acquisto degli stessi non viene portato a termine, i prodotti possono essere acquistati da altri clienti.

                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 12 - Garanzie dei Prodotti</h2>
                <p style="text-align: left;">
                    I prodotti acquistati sono soggetti alle garanzie previste dal Decreto legislativo n. 206 del 6 settembre 2005 (Codice del Consumo) e successive modifiche e integrazioni.
                    Tale garanzia, si applicherà al prodotto che presenti difetti di conformità e/o malfunzionamenti non riscontrabili al momento dell'acquisto, purché il prodotto stesso sia utilizzato correttamente e con la dovuta diligenza.
                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 13 - Garanzia legale per il Consumatore</h2>
                <p style="text-align: left;">
                    Il Cliente ha diritto di avvalersi della garanzia legale prevista dagli artt. 128 e seg. del Codice di Consumo.
                    I prodotti consegnati sono conformi alle caratteristiche illustrate online nelle relative schede descrittive e tecniche.
                    Remindmevision.com è responsabile nei confronti del consumatore per i difetti di conformità esistenti al momento della consegna del bene.
                    Nel caso in cui il bene consegnato presenti un difetto di conformità, il consumatore ha diritto al ripristino della conformità del bene, previa tempestiva comunicazione a Remindmevision.com nei termini previsti dagli artt, 128 e segg. del Codice del Consumo (Decreto legislativo n. 206 del 6 settembre 2005 e successive modifiche e integrazioni).
                    Il predetto diritto è riconosciuto in capo al consumatore purché il bene consegnato sia stato utilizzato correttamente, nel rispetto della sua destinazione e di quanto previsto nelle istruzioni d'uso allegate al prodotto acquistato dal Cliente.
                    In particolare, la predetta garanzia non si applica al bene che presenta difetti di conformità che il consumatore conosceva al momento della conclusione del contratto o non poteva ignorare con l'uso dell'ordinaria diligenza.
                    La Garanzia di cui sopra è valida per il termine massimo di 14 giorni dalla Consegna del Prodotto. Oltre tale termine, Remindmevision.com pertanto, non sarà responsabile dei difetti di conformità riscontrati dal consumatore. In caso di sostituzione o riparazione del prodotto, i termini della garanzia relativa al Prodotto dato in sostituzione o risultante dalla riparazione, sono i medesimi del prodotto originario.
                    In caso di difetto di conformità, Remindmevision.com provvede, senza spese per il Cliente, al ripristino della conformità del prodotto mediante sostituzione.
                    Se non dovesse risultare un difetto di conformità, ai sensi del DLgs n. 24/02, al Cliente saranno addebitati gli eventuali costi di verifica, nonché i costi di trasporto se sostenuti da Remindmevision.com.
                    Nessun danno può essere richiesto a Remindmevision.com per eventuali ritardi nella sostituzione di prodotti in garanzia.
                    Nei casi in cui l'applicazione delle garanzie preveda la restituzione del prodotto, il bene dovrà essere restituito dal Cliente, previa richiesta di numero di autorizzazione al Reso diretta all'Assistenza Clienti (MANCA LA MAIL DI ASSISTENZA), nella confezione originale, completa in tutte le sue parti e dovrà evitare, in tutti i casi, l'apposizione di etichette o nastri adesivi direttamente sulla confezione originale del prodotto. Il Cliente dovrà scrivere il numero di autorizzazione sul pacco o sui pacchi che restituisce.

                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 14 - Danni Occulti</h2>
                <p style="text-align: left;">
                    Qualora dovesse emergere un difetto, vizio, non conformità all'ordine o quant'altro, solo dopo avere rimosso gli imballi, il Cliente deve inviare a Remindmevision.com immediata informazione e relativa documentazione fotografica per posta elettronica entro 24 ore all'indirizzo e-mail MANCA LA MAIL DI ASSISTENZA.
                    Una volta ricevuta la richiesta di reso, l'ufficio Assistenza Clienti di Remindmevision.com invierà al Cliente una conferma scritta contenente il “numero di autorizzazione al reso”, l'indirizzo al quale rispedire il pacco. Il Cliente dovrà scrivere il numero di autorizzazione sul pacco o sui pacchi che restituisce.
                    Il bene acquistato dovrà essere restituito presso la sede di Remindmevision.com inutilizzato e integro, nella sua confezione originale, completa in tutte le sue parti (compreso l'imballo, la documentazione e la dotazione accessoria), entro un termine non superiore a 14 gg lavorativi, a partire dal ricevimento della merce. Verificata l'esistenza del difetto contestato, Remindmevision.com provvederà ad inviare gratuitamente il componente difettoso nel minor tempo possibile.
                    Le sostituzioni saranno effettuate all'indirizzo di spedizione inserito nell'ordine.
                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 15 - Sostituzioni</h2>
                <p style="text-align: left;">
                    La merce viene sostituita a titolo gratuito se nel documento di trasporto viene indicato il danno subito o viene trascritta la dicitura "Accettazione con riserva" specificandone il motivo.
                    Tali danni o difetti, evidenti o presunti, dovranno essere segnalati al momento dello scarico indicando il tipo e l'entità, dando immediata informazione a scontolo.net con posta elettronica entro 24 ore (all'indirizzo MANCA LA MAIL DI ASSISTENZA)
                    In alcuni casi, i tempi di reintegro dei prodotti possono essere simili a quelli di produzione indicati nella scheda prodotto.
                    Le sostituzioni saranno effettuate all'indirizzo di spedizione inserito nell'ordine

                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 16 - Diritto di Recesso</h2>
                <p style="text-align: left;">
                    Ai sensi e nei limiti di cui al Decreto Legislativo n. 206/2005 e successive modifiche (Codice del Consumo), il Cliente ha diritto, entro 14 giorni dal ricevimento della merce richiesta, di esercitare il diritto di recesso.
                    Il Cliente non potrà esercitare il diritto di recesso per i “beni confezionati su misura o chiaramente personalizzati" (es. photobook personalizzato, magazine e libro autobiografico etc.) ex art. 59 Codice del Consumo, così come modificato dal Decreto legislativo n. 21 del 21.02.2014.

                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 17 - Modalità per l'esercizio del Diritto di Recesso</h2>
                <p style="text-align: left;">
                    Per l'esercizio del diritto di recesso, il Cliente dovrà inoltrare, entro il termine di cui all'art. 25 delle presenti Condizioni Generali di Vendita, comunicazione via e-mail all'indirizzo di posta elettronica MANCA LA MAIL DI ASSISTENZA o a mezzo lettera racc. a.r. indirizzata a:
                    RemindMe S.r.l., Corso giacomo Matteotti 42 10121 Torino (TO)
                    il diritto di recesso è comunque sottoposto alle seguenti condizioni:
                    1. Il bene acquistato dovrà essere restituito, presso la sede operativa di Remindmevision.com, Strada del Mondino 13/D Castiglione Torinese (TO) 10090, inutilizzato e integro, nella sua confezione originale completa in tutte le sue parti (compreso l'imballo e documentazione e dotazione accessoria), entro un termine non superiore a 14 giorni dal ricevimento della merce.
                    2. Il Cliente è responsabile della diminuzione del valore risultante da una manipolazione diversa da quella necessaria per stabilire la natura, le caratteristiche e il funzionamento del bene stesso.
                    3. Le spese di spedizione relative alla restituzione del bene sono a carico del Cliente.
                    4. Una volta ricevuta la richiesta di reso, l'ufficio Assistenza Clienti di Remindmevision.com invierà al Cliente una conferma scritta contenente il numero di Autorizzazione al Reso e l'indirizzo al quale rispedire il pacco. Il Cliente dovrà scrivere il numero di autorizzazione sul pacco o pacchi che restituisce. Il Cliente dovrà evitare di apporre etichette o nastri adesivi sulla confezione originale del prodotto.
                    5. Il diritto si applica al prodotto acquistato nella sua interezza; non è possibile esercitare il recesso solamente su parte del prodotto acquistato (es: accessori, software, allegati, etc.)
                    6. La spedizione, fino all'attestato di avvenuto ricevimento nel nostro magazzino, è sotto la completa responsabilità del Cliente. Remindmevision.com non risponde in nessun modo per danneggiamenti o furto/smarrimento di beni restituiti con spedizioni non assicurate o non tracciate (es; posta prioritaria).
                    Il diritto di recesso decade totalmente, per mancanza della condizione essenziale di integrità del bene (confezione e/o suo contenuto), nei casi in cui Remindmevision.com accerti:
                    1. La mancanza della confezione esterna e/o dell'imballo interno originale.
                    2. L'assenza di elementi integranti del prodotto (accessori, cavi, manuali, parti, ...) o anomalie al prodotto stesso.
                    3. Il danneggiamento del prodotto per cause diverse dal suo trasporto.
                    Ove il Cliente esercita il diritto di recesso nel rispetto delle disposizioni dettate dalle presenti Condizioni Generali di Vendita, Remindmevision.com rimborserà tutti i pagamenti ricevuti dal Cliente, eventualmente comprensivi di spese di consegna, ad eccezione dei costi supplementari che il Cliente ha confermato espressamente in aggiunta alla consegna standard offerta dal sito (es. supplementi per località speciali, servizi di consegna al piano, etc.).
                    Remindmevision.com procederà al riaccredito attraverso la stessa modalità utilizzata per il pagamento dell'ordine nel minor tempo possibile e in ogni caso entro venti giorni dalla data in cui Remindmevision.com è venuta a conoscenza dell'esercizio del diritto di recesso e comunque non prima della ricezione dei beni da parte del Cliente.
                    Remindmevision.com respingerà conseguentemente qualsiasi prodotto restituito non imballato o comunque con modalità diverse da quelle sopra specificate, così come i prodotti per i quali non siano state integralmente già pagate dal Cliente le spese di restituzione (ad es. spedizione a carico del destinatario).
                    All'arrivo in magazzino, il prodotto sarà esaminato per valutare eventuali danni o manomissioni non derivanti da trasporto. Qualora la confezione e/o imballaggio originale risultino rovinati, provvederà a trattenere dal rimborso dovuto una percentuale, comunque non superiore al 10% dello stesso, quale contributo alle spese di ripristino.
                    Remindmevision.com respingerà altresì qualsiasi prodotto nel caso non siano state rispettate le modalità e i tempi previsti dal D.lgs. 6 settembre 2005 n.206 e successive integrazioni e modificazioni, tra le quali il decreto legislativo 21 febbraio 2014, n. 21.

                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 18 - Contatti</h2>
                <p style="text-align: left;">
                    Per qualsiasi chiarimento o eventuale reclamo il Cliente potrà contattare Remindmevision.com via e-mail scrivendo a MANCA LA MAIL DI ASSISTENZA.
                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 19 - Iscrizione al sito web</h2>
                <p style="text-align: left;">
                    Per acquistare tramite il Sito di Remindmevision.com è necessario registrarsi, creando un account e approvando le Condizioni Generali, cliccando sull'apposita voce “Registrati” o “Crea un account” presente in tutte le pagine del Sito.
                    La registrazione al Sito è gratuita. Per registrarsi l'utente deve compilare il modulo di registrazione, inserendo nome, cognome, un indirizzo e-mail e una password (di seguito indicate come “Credenziali di Registrazione”). La registrazione viene confermata a mezzo mail inviata all'indirizzo fornito dall'utente.
                    Le Credenziali di Registrazione devono essere utilizzate esclusivamente dall'utente e non possono essere cedute a terzi. L'utente deve avvisare tempestivamente Remindmevision.com in caso di sospetto uso indebito delle stesse. Le Credenziali di Registrazione potranno essere modificate dall'utente in qualsiasi momento tramite l'accesso sul Sito alla sezione “Account”.
                    L'utente garantisce che le Credenziali di Registrazione fornite durante la procedura di registrazione al Sito sono complete, corrette e veritiere. L'utente accetta di tenere Remindmevision.com indenne da qualsiasi obbligo risarcitorio, sanzione derivante e/o in qualsiasi modo collegati alla violazione da parte dell'utente delle regole sulla registrazione al Sito. L'utente è esclusivo responsabile dell'accesso al Sito mediante le Credenziali di Registrazione e risponde direttamente di ogni danno o pregiudizio arrecato a Remindmevision.com o a terze parti da un uso improprio, dalla perdita, dall'appropriazione indebita da parte di altri, ovvero dalla mancata tutela di un'adeguata segretezza delle proprie Credenziali di Registrazione. Tutte le operazioni effettuate tramite le Credenziali di Registrazione sono considerate effettuate dal Cliente a cui le Credenziali di Registrazione si riferiscono.
                    Con la registrazione al Sito l'utente accetta di ricevere gli inviti di partecipazione alle vendite e alle proposte commerciali di Remindmevision.com (Newsletter). In qualsiasi momento, cliccando sull'apposito link presente in calce all'invito, l'utente potrà richiedere di non ricevere ulteriori inviti di partecipazione alle vendite. L'utente potrà in ogni caso partecipare alle vendite mediante accesso diretto tramite le proprie credenziali nel Sito.
                    I Servizi sono erogati in lingua italiana.
                    È possibile effettuare una singola registrazione per utente.
                    Sul sito Remindmevision.com è fatto divieto all'utente di effettuare registrazioni doppie o multiple, salvo eventuali necessità peculiari o casi particolari per i quali è necessario richiedere espressa autorizzazione agli amministratori del sito. Qualora un utente si registri più volte al sito con diversi account e venga scoperto dall'amministrazione, gli account successivi al più vecchio saranno sospesi o resi inattivi. Per motivi tecnici, in nessun caso è possibile sommare su un unico account ordini, promozioni e qualsiasi genere di informazione presente, utilizzando due o più account.
                    Nell'area personale “I miei ordini” sul Sito l'utente può visualizzare gli ordini in corso, spediti di recente e/o conclusi. Le modifiche all'ordine sono da ritenersi accettate solo dopo ricevimento di conferma scritta da parte di Remindmevision.com dell'avvenuta variazione. L'utente potrà gestire i dati personali nella sezione “Account”.
                    Remindmevision.com si riserva la possibilità di rifiutare, a propria esclusiva discrezione, l'iscrizione di alcun utente. Remindmevision.com si riserva inoltre il diritto di non accettare ordini, da chiunque provenienti, che risultino anomali in relazione alla quantità dei prodotti acquistati ovvero alla frequenza degli acquisti effettuati sul Sito, nonché in relazione all'utilizzo improprio o sospetto di metodi di pagamento o buoni regalo.
                    Remindmevision.com può, a propria sola ed esclusiva discrezione, disattivare l'account di un utente o interromperne l'utilizzo. Per esempio, Remindmevision.com potrà esercitare tale facoltà nel caso in cui ritenga che l'utente abbia violato o agito in maniera incompatibile con le presenti condizioni generali, della netiquette e normativa vigente.
                    L'utente potrà in qualsiasi momento eliminare il proprio account o cancellarsi dalla Newsletter facendone comunicazione a Remindmevision.com tramite i canali indicati sul sito nella sezione “Contatti”. Per la Newsletter sarà anche possibile decidere di cancellarsi attraverso il link presente in ogni mail.
                    La registrazione è da ritenersi gratuita ed a tempo indeterminato; l'utente, nel rispetto delle normative a tutela della privacy e nella piena libertà contrattuale manterrà sempre pieno diritto di sospendere i servizi, gli account e quant'altro associato all'utente unilateralmente il proprio account mediante una richiesta scritta via e-mail nella quale fa esplicita richiesta che il suo account venga sospeso dall'accesso e che vengano cancellate la relativa password e l'indirizzo e-mail a esso associato.
                    L'utente è consapevole che, attraverso la cancellazione del proprio account, perderà tutte le sue informazioni relative a dati personali, ordini, promozioni anche tutti i servizi acquistati sul sito Remindmevision.com senza possibilità di riscossione, poiché cancellati anche dai server di Remindmevision.com.
                    Qualora l'utente “cancellato” intenda registrarsi nuovamente al portale sarà necessaria una ulteriore iscrizione.

                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 20 - Utilizzo sito web</h2>
                <p style="text-align: left;">
                    Articolo 22 - Utilizzo sito web
                    22. 1 Proprietà intellettuale
                    Gli elementi pubblicati sul sito Remindmevision.com, ivi inclusi, in particolare, il logo, i marchi, costituiscono opere protette dai diritti di proprietà intellettuale o diritti della persona e sono di proprietà di RemindMe S.r.l.. La riproduzione o la rappresentazione, totale o parziale, di questo sito è quindi vietata senza previo ed espresso accordo dei titolari di tali diritti e può costituire un atto di contraffazione sanzionato dalle disposizioni del Codice di proprietà intellettuale e/o un fatto delittuoso tale da implicare la responsabilità civile dei contravventori dei suddetti diritti.
                    22.2 Responsabilità dell'utente
                    La responsabilità della navigazione sul sito http://www.remindmevision.com, è totalmente a carico dell'utente. Remindmevision.com non potrà essere ritenuta responsabile di guasti, errori o virus informatici che potrebbero ostacolare la continuità di accesso al suo sito o dei malfunzionamenti che si potrebbero riscontrare nel PC degli utenti in seguito a un accesso al sito. Di conseguenza, non sarà tenuta alla riparazione dei danni diretti o indiretti che potrebbero essere legati all'utilizzo, all'accesso al sito o al download di elementi contenuti nel sito (immagini, testi, file video).
                    22.3 Terze Parti
                    E' possibile che su Remindmevision.com siano inclusi collegamenti ipertestuali ad altri siti web o fonti esterne a tale sito, compresi gli inserzionisti.
                    Remindmevision.com non esamina tutti i siti collegati al proprio sito web e non è responsabile per il contenuto o l'esattezza di eventuali pagine esterne al sito.
                    Remindmevision.com non è responsabile per la disponibilità di tali siti o fonti esterne e non approva e non è ritenuto responsabile - direttamente o indirettamente - delle pratiche di privacy o dei contenuti di tali siti web, tra cui (a titolo esemplificativo) pubblicità, prodotti o altro materiale o servizi presenti o disponibili su tali siti o fonti.
                    Remindmevision.com non è responsabile né per qualsiasi danno, perdita o reato causati - o presumibilmente causati da - o in connessione con, l'uso di tali contenuti, beni o servizi disponibili su tali siti o fonti esterne.
                    22.4 Declinazione di Responsabilità
                    Remindmevision.com si riserva, inoltre, il diritto di apportare correzioni e cambiamenti al sito ogni qual volta lo ritenga necessario senza darne preavviso. Remindmevision.com non offre alcuna garanzia sulla conformità delle informazioni pubblicate nel proprio sito alle leggi previste dalla giurisdizione del paese di residenza del Cliente. Remindmevision.com declina ogni responsabilità relativa a eventuali problemi, danni o rischi che l'utente può riscontrare durante l'utilizzo del sito e garantisce che il proprio sito sia protetto secondo gli standard internazionali previsti per Internet. Se usato correttamente, l'utente è protetto dal rischio di virus.
                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 21 - Forza maggiore</h2>
                <p style="text-align: left;">
                    Remindmevision.com non sarà responsabile del ritardo nell'adempimento del contratto regolato dalle presenti Condizioni Generali di Vendita, se tale ritardo è causato da circostanze al di fuori del loro ragionevole controllo. La parte in ritardo per causa di forza maggiore avrà diritto ad una proroga dei termini necessaria per adempiere tali obbligazioni.
                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 22 - Prova</h2>
                <p style="text-align: left;">
                    Per espresso accordo tra Remindmevision.com e il Cliente, le e-mail faranno fede tra le parti, così come i sistemi di registrazione automatici utilizzati nel Sito, nella fattispecie per quanto riguarda la natura e la data dell'ordine.
                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 23 - Parziale nullità</h2>
                <p style="text-align: left;">
                    Se una o più clausole delle presenti Condizioni Generali di Vendita sono considerate nulle o dichiarate tali in applicazione di una legge, di un regolamento o in seguito a una decisione definitiva di una giurisdizione competente, le altre clausole manterranno tutto il loro vigore e la loro portata.
                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 24 - Modifica delle Condizioni Generali di vendita</h2>
                <p style="text-align: left;">
                    Remindmevision.com si riserva il diritto di modificare le presenti Condizioni Generali di Vendita.
                    La versione online sul Sito al momento dell'ordine da parte dell'acquirente prevarrà su ogni altra versione precedente delle presenti Condizioni Generali di Vendita.
                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 25 - Integrità del contratto</h2>
                <p style="text-align: left;">
                    Le presenti Condizioni Generali di vendita e il riepilogo dell'ordine trasmessi all'acquirente formano un insieme contrattuale e costituiscono l'integralità delle relazioni contrattuali intervenute tra le parti.
                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Articolo 26 - Dati Personali</h2>
                <p style="text-align: left;">
                    Il titolare del trattamento dei dati personali, trattati a seguito della consultazione del sito Remindmevision.com è RemindMe S.r.l., sita in Torino (TO) 10121 in Corso Giacomo Matteotti 42.
                    Nessun dato derivante dal servizio web viene comunicato o diffuso a terzi. I dati personali forniti dagli utenti che inoltrano richieste di servizi sono utilizzati al solo fine di eseguire il servizio o la prestazione richiesta e non sono comunicati a soggetti terzi salvo che la comunicazione sia imposta dagli obblighi di legge o sia strettamente necessario per l'adempimento delle richieste. L'informativa non è valida per altri siti web eventualmente consultabili attraverso i nostri links, per quest'ultimi Remindme S.r.l. non è in alcun modo responsabile.

                    RemindMe S.r.l. invita il Cliente a consultare sul sito Remindmevision.com l'Informativa Privacy resa ai sensi dell'art.13 e segg. del Decreto Legislativo 30 giugno 2003 n. 196 (CODICE DELLA PRIVACY), contenente le condizioni e finalità del trattamento dei dati personali, che costituiscono parte integrante delle presenti Condizioni Generali di Vendita.

                </p>
            </vs-row>
            <vs-row direction="column" style="width:80%;">
                <h2 class="stage" style="font-size:16px;">Metodi di pagamento</h2>
                <p style="text-align: left;">
                    - Carte di Credito / Debito
                </p>
            </vs-row>
            
        </vs-row>
    </div>
</div>
</template>

<script>
import AOS from 'aos';
export default ({
    mounted() {
        AOS.init();
    },

})
</script>
